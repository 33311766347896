<template>
  <div>
    <div
      class="intro-container row-24 m-0 justify-center items-center"
      :style="{backgroundImage: `url(${backgroundImgUrl})`}"
      @click="scrollDown"
    >
      <!-- <h1 class="intro-title text-white col-16" v-html="payload.introtext" /> -->
      <ScrollIndicator />
    </div>
    <div class="row-24 pt-8 md:pt-16 mx-0 bg-white">
      <div class="einleitung-container col-22 offset-1 md:col-14 md:offset-5">
        <span class="text-red">Hallo. Wir sind schau. </span>
        <span v-html="payload.einleitung" />
      </div>
      <ContactBlock :contact-info="payload" />
    </div>
    <section class="personen-section bg-white row-24 m-0">
      <div
        class="personen-container flex flex-wrap row-24 col-22 offset-1
        sm:col-16 sm:offset-4 md:offset-4 lg:offset-4 m-0"
      >
        <div
          v-for="person in personen"
          :key="person.id"
          class="personen-card col-24 sm:col-12 md:col-12 xl:col-8 m-0 flex flex-col justify-center items-center"
          @mousemove="getMousePosition"
        >
          <h2
            class="person-name"
            v-html="person.name"
          />
          <h3
            class="person-funktion"
            v-html="person.funktion"
          />
          <div class="person-portrait" :style="{ top: mouseY + 'px', left: mouseX + 'px' }">
            <img :src="person.portrait.normal" :alt="person.portrait.alt">
            <div class="person-description" v-html="person.beschreibung" />
          </div>
        </div>
      </div>
    </section>
    <section class="kunden-section bg-white row-24 mx-0">
      <div class="kunden-title col-16 offset-2 md:offset-6 lg:col-8 lg:offset-8">
        <h4>Kunden</h4>
      </div>
      <div class="kunden-container col-16 offset-2 md:offset-6 md:col-12 lg:col-8 lg:offset-8">
        <span
          v-for="kunde in payload.kunden"
          :key="kunde.id"
          class="kunde"
        >{{ kunde.title }}</span>
      </div>
    </section>
  </div>
</template>

<script>

import ScrollIndicator from '../parts/ScrollIndicator.vue';
import ContactBlock from '../parts/ContactBlock.vue';

export default {
  components: {
    ScrollIndicator,
    ContactBlock,
  },
  props: {
    payload: Object,
  },
  data() {
    return {
      personen: this.payload.personen,
      mouseX: 0,
      mouseY: 0,
    };
  },
  computed: {
    backgroundImgUrl() {
      return this.payload.hero.large;
    },
  },
  methods: {
    getMousePosition(event) {
      const card = event.target.getBoundingClientRect();
      const x = event.clientX - card.left;
      const y = event.clientY - card.top;
      this.mouseX = x;
      this.mouseY = y;
    },
    scrollDown() {
      window.scroll({
        top: window.innerHeight,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";

.einleitung-container {
  :deep(span) {
    line-height: 1.25;
    font-size: px(18);
    @screen md {
      font-size: px(22);
    }
    h2, h3, h4, p, a {
      display: inline;
      line-height: 1.25;
      font-size: px(18);
      @screen md {
        font-size: px(22);
      }

    }
  }
}

.personen {
  &-section {
    background-color: var(--color-white);
    padding: 4rem 0;
    @screen lg {
      padding:  12rem 0 12rem 0;
    }
  }
}
.personen-card {
  position: relative;
  cursor: crosshair;
  padding: 50px 0;
  @screen md {
    padding: 75px 0;
  }
  &:hover {
    .person-portrait {
      opacity: 1;
      z-index: 4;
    }
    h2, h3 {
      z-index: 5;
      color: var(--color-white);
    }
  }
  .person-name {
    font-family: var(--font-primary);
    color: var(--color-black);
    pointer-events: none;
    margin: 0 0 1rem 0;
    :deep(p) {
      margin: 0;
      z-index: 5;
      text-align: center;
      font-size: 2rem;
      line-height: 1.1;
      @screen md {
        font-size: 2rem;
      }
      @screen lg {
        font-size: 2.5rem;
      }
    }
  }
  .person-funktion {
    pointer-events: none;
    margin: 0;
    color: var(--color-black);
    font-size: px(16);
    text-transform: uppercase;
    text-align: center;
    @screen md {
      font-size: 1.1rem;
    }
  }
  .person-portrait {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    min-height: px(200);
    opacity: 0;
    @screen md {
      min-height: px(300);
    }
    @screen lg {
      min-height: px(350);
    }

    .person-description {
      max-width: 100%;
      background-color: var(--color-white);
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      margin: 0;
    }
  }
}

.kunden {
  &-container {
    padding-bottom: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @screen md {
      flex-wrap: wrap;
      height: px(300);
    }
  }
}
</style>
