<template>
  <div class="contact-block row-24 col-22 offset-0 md:col-14 md:offset-5 my-8 md:my-16 mx-0">
    <div class="contact-cell col-22 offset-1 md:col-5 md:offset-0 flex-col">
      <p v-html="contactInfo.adresse" />
      <p class="kontaktperson" v-html="contactInfo.kontaktperson" />
    </div>
    <div class="contact-cell col-22 offset-1 md:col-5 md:offset-0">
      <p v-html="contactInfo.kontaktallgemein" />
    </div>
    <div class="contact-cell col-22 offset-1 md:col-5 md:offset-0">
      <p v-html="contactInfo.oeffnungszeiten" />
    </div>
    <div class="contact-cell col-22 offset-1 md:col-5 md:offset-0">
      <p v-html="contactInfo.somelinks" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contactInfo: Object,
  },
};
</script>

<style lang="scss" scoped>
.contact-block {
  :deep(p) {
    margin: 0;
    a {
      color: var(--color-black);
    }
  }
  .kontaktperson {
    margin-top: 1rem;
  }
}
</style>
